import { scroller } from 'react-scroll';
import Constants from './Constants';

const scrollTarget = (target: string) =>
  scroller.scrollTo(target, {
    spy: true,
    smooth: true,
    offset:
      target === Constants.STAYING ||
      target === Constants.ACCOMMODATIONS
        ? 64
        : 0,
    duration: 500,
  });

const scrollToPage = async (target: string, location: any, navigate: any) => {
  if (target === Constants.VILLA_MARE) {
    await navigate(`/${target}`);
    scrollTarget(target);
  } else if (target === Constants.VILLA_LUCE) {
    await navigate(`/${target}`);
    scrollTarget(target);
  } else if (location.pathname !== '/' && target !== Constants.FOOTER) {
    await navigate('/');
    scrollTarget(target);
  } else {
    scrollTarget(target);
  }
};

export default scrollToPage;
