import React, { useState } from 'react';
import Slider from 'react-slick';
import Constants from '../../../../utils/Constants';
import geographyImg from '../../../../assets/imgs/home/01_staying/geography.jpg'
import activitieImg from '../../../../assets/imgs/home/01_staying/activities.jpg'
import gastronomyImg from '../../../../assets/imgs/home/01_staying/gastronomy.jpg';
import cultureImg from '../../../../assets/imgs/home/01_staying/culture.jpg';
import ScrollAnimation from '../../../../utils/ScrollAnimation';

const Staying = ({ lang }: { lang: Locale.Keys }) => {

  const geography = () => {
    return (
      <>
        <h2 className='title_underline_dark_small'>
          {lang.staying.geographyTitle}
        </h2>
        <p>{lang.staying.geography_p1}</p>
      </>
    );
  };

  const activities = () => {
    return (
      <>
        <h2 className='title_underline_dark_small'>
          {lang.staying.activitiesTitle}
        </h2>
        <p>{lang.staying.activities_p1}</p>
      </>
    );
  };

  const gastronomy = () => {
    return (
      <>
        <h2 className='title_underline_dark_small'>
          {lang.staying.gastronomyTitle}
        </h2>
        <p>{lang.staying.gastronomy_p1}</p>
      </>
    );
  };

  const culture = () => {
    return (
      <>
        <h2 className='title_underline_dark_small'>
          {lang.staying.cultureTitle}
        </h2>
        <p>{lang.staying.culture_p1}</p>
      </>
    );
  };

  const [count, setCount] = useState<number>(1);
  const [preventDoubleClick, setPreventDoubleClick] = useState<boolean>(false);
  const transitionSpeed: number = 500;

  const stayingCoraTexts: any[] = [geography, activities, gastronomy, culture];
  const stayingCoraImgs: string[] = [
    geographyImg,
    activitieImg,
    gastronomyImg,
    cultureImg,
  ];

  const sliderText = React.useRef<Slider | null>(null);
  const sliderImg = React.useRef<Slider | null>(null);

  const nextSlide = () => {
    if (!preventDoubleClick) {
      setPreventDoubleClick(true);

      sliderText.current?.slickNext();
      sliderImg.current?.slickNext();

      if (count === stayingCoraImgs.length) {
        setCount(1);
      } else {
        setCount(count + 1);
      }

      setTimeout(function () {
        setPreventDoubleClick(false);
      }, transitionSpeed + 100);
    }
  };

  const prevSlide = () => {
    if (!preventDoubleClick) {
      setPreventDoubleClick(true);

      sliderText.current?.slickPrev();
      sliderImg.current?.slickPrev();

      if (count === 1) {
        setCount(stayingCoraImgs.length);
      } else {
        setCount(count - 1);
      }

      setTimeout(function () {
        setPreventDoubleClick(false);
      }, transitionSpeed);
    }
  };

  const slickSettingsOne = {
    adaptiveHeight: true,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const slickSettingsTwo = {
    adaptiveHeight: false,
    draggable: false,
    swipe: false,
    arrows: false,
    fade: true,
    infinite: true,
    speed: transitionSpeed,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div id={Constants.STAYING}>
      <div className='discover container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
          <ScrollAnimation animateIn='fadeIn' duration={1000}>
            <h2 className='text_center'>{lang.staying.title}</h2>
            <div className='discover_container'>
              <div className='discover_container_text'>
                <Slider ref={sliderText} {...slickSettingsOne}>
                  {stayingCoraTexts.map((x: any) => x())}
                </Slider>
                <div className='discover_container_buttons'>
                  <div
                    className='opacity_transition_low'
                    onClick={() => prevSlide()}
                  >
                    {Constants.arrowLeft(Constants.complementaryColorDark)}
                  </div>
                  <div
                    className='opacity_transition_low'
                    onClick={() => nextSlide()}
                  >
                    {Constants.arrowRight(Constants.complementaryColorDark)}
                  </div>
                  <div className='discover_container_buttons_counter'>
                    {count} / {stayingCoraTexts.length}
                  </div>
                </div>
              </div>
              <div className='discover_container_img'>
                <Slider ref={sliderImg} {...slickSettingsTwo}>
                  {stayingCoraImgs.map((x: string) => (
                    <img
                      src={x}
                      alt={`staying at cora ${
                        stayingCoraImgs[stayingCoraImgs.indexOf(x)]
                      }`}
                    />
                  ))}
                </Slider>
              </div>
            </div>
          </ScrollAnimation>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Staying;
