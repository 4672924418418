export const hr: Locale.Keys = {
  menu: {
    ourStory: 'OUR STORY',
    villas: 'VILLAS',
    stayingPeljesac: 'STAYING AT PELJEŠAC',
    gallery: 'GALLERY',
    location: 'LOCATION',
    contactUs: 'CONTACT US',
    bookNow: 'BOOK NOW',
  },
  about: {
    t: 'Our Story',
    p1: 'Nestled on the stunning Pelješac peninsula, these exquisite luxury villas offer an unparalleled escape into coastal elegance.',
    p2: 'With commanding views of the azure Adriatic Sea and the rugged Dalmatian coastline, the villa`s location sets the stage for a truly captivating retreat. The meticulously designed interior seamlessly blends modern opulence with Mediterranean charm, featuring spacious living areas, state-of-the-art amenities, and indulgent touches. Outside, a private infinity pool overlooks the sea, inviting relaxation amidst breathtaking surroundings.',
    p3: 'From their prime vantage point, the villas present a gateway to the peninsula`s renowned wineries, idyllic beaches, and historic towns, ensuring an unforgettable and refined experience for those seeking the finest in coastal living.',
  },
  services: {
    t: 'FACILITIES AND SERVICE',
    gym: 'GYM',
    sauna: 'SAUNA',
    parking: 'PARKING',
    bicycle: 'BICYCLE',
    sup: 'SUP',
    wine: 'WINE',
  },
  accomodations: {
    title: 'VILLAS',
    subtitle: 'YOUR PRIVATE PLACE OF LUXURY',
    p1: 'These villas offer privacy and luxury, making them a popular choice for travellers seeking a tranquil and exclusive getaway. With spacious rooms with private bathrooms, fully equipped kitchens, balconies with sea views, and a private pool, these villas are ideal for families or groups of friends. They offer guests flexibility in planning daily activities and exploring the coastline, whether you`re interested in an active vacation or simply lounging on the beach. Seafront vacation villas provide a comfortable and luxurious setting for your relaxation and enjoyment.',
    luceTitle: 'Villa Luce',
    mareTitle: 'Villa Mare',
    showMore: 'SHOW MORE',
  },
  villaLuce: {
    title: 'VILLA LUCE',
    subtitle: 'LUCE',
    p1: 'This luxurious seaside villa offers a perfect retreat for those seeking the ultimate vacation experience. With a spacious living room with large windows, a fully equipped kitchen, and bedrooms with private bathrooms this vacation villa offers the perfect blend of comfort, elegance, and spectacular views of the sea',
  },
  villaMare: {
    title: 'VILLA MARE',
    subtitle: 'MARE',
    p1: 'This exquisite vacation villa is situated in a stunning location right on the waterfront. With its extraordinary views of the azure blue sea and picturesque sunsets, this villa offers an incredible experience of relaxation and luxury.',
  },
  staying: {
    title: 'STAYING AT PELJEŠAC',
    geographyTitle: 'GEOGRAPHY',
    geography_p1:
      'Pelješac, a picturesque peninsula located in southern Croatia, is a captivating blend of natural beauty, rich history, and authentic Mediterranean charm. Stretching into the azure waters of the Adriatic Sea, Pelješac boasts a rugged coastline adorned with hidden coves, pristine beaches, and dramatic cliffs.',
    activitiesTitle: 'ACTIVITIES',
    activities_p1:
      'Outdoor enthusiasts will find ample opportunities for adventure, from hiking along scenic trails that lead to breathtaking viewpoints to indulging in water sports in crystal-clear waters. Whether you`re seeking relaxation, cultural exploration, or active pursuits, Pelješac promises a memorable escape where the Adriatic`s beauty and Pelješac`s distinct character merge into an enchanting experience',
    gastronomyTitle: 'GASTRONOMY',
    gastronomy_p1:
      'Pelješac is a paradise for food enthusiasts, offering a delectable array of fresh seafood, olive oil, and locally grown produce. From enjoying an al fresco meal overlooking the sea to embarking on a wine-tasting journey through the vineyards, Pelješac offers a sensory experience that celebrates the region`s bountiful offerings.',
    cultureTitle: 'CULTURE',
    culture_p1:
      'The region is renowned for its vineyards that produce some of Croatia`s finest wines, particularly the robust Dingač variety. As you explore the charming towns and villages scattered across the peninsula, you`ll encounter ancient stone houses, narrow streets, and charming squares, each telling stories of centuries past.',
  },
  gallery: {
    title: 'GALLERY',
  },
  location: {
    title: 'LOCATION',
  },
  amenities: {
    entertainment: {
      title: 'ENTERTAINMENT',
      tv: 'LCD Smart TV in the living room',
      tvRoom: 'LCD TV in every room',
      poolTable: 'Pool table',
      sauna: 'Sauna',
      gym: 'Gym',
      pool: 'Heated/Cooled swimming pool',
      wifi: 'Wi-Fi',
      barbecue: 'Barbecue',
    },
    roomFeatures: {
      title: 'ROOM FEATURES',
      rooms: '3 rooms (6 people)',
      ac: 'Air conditioning',
      safe: 'Safe deposit box',
      wardrobe: 'Wardrobe',
      smoking: 'Non-smoking',
      beddings: 'Fine beddings',
      pillows: 'Hypoallergenic pillows',
      curtains: 'Thick curtains',
    },
    bath: {
      title: 'BATHROOM',
      shower: 'Walk-in shower',
      toiletries: 'Toiletries',
      towels: 'Towels',
      hairdryer: 'Hairdryer',
    },
    dining: {
      title: 'DINING',
      kitchen: 'A full kitchen',
      tableAndChairs: 'Kitchen table and chairs',
      cutlery: 'Cutlery',
    },
    additional: {
      title: 'ADDITIONAL SERVICES',
      parking: 'Free parking',
      coffe: 'Coffe maker',
      washmachine: 'Washing machine',
      microwave: 'Microwave',
      kettle: 'Kettle',
      beach: 'Beach Equipment',
      bikes: 'Bikes for rent',
      wellcomePack: 'Welcome pack',
    },
  },
};
