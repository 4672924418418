import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Constants from '../../utils/Constants';
import logo from '../../assets/imgs/logo_gold.svg';
import menu from '../../assets/imgs/menu.jpg';
import scrollToPage from '../../utils/ScrollToLink';
import scrollDirection from '../../utils/ScrollDirection';

const Header = ({
  currentLanguage,
  changeLanguage,
  lang,
}: {
  currentLanguage: string;
  changeLanguage: Function;
  lang: Locale.Keys;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const direction = scrollDirection();

  // const toggleLanguage = () => {
  //   const newLanguage =
  //     currentLanguage === Constants.lang.en
  //       ? Constants.lang.hr
  //       : Constants.lang.en;
  //   changeLanguage(newLanguage);
  // };

  const [openMenu, setOpenMenu] = useState<boolean>(false);

  let header: HTMLElement | null = document.getElementById('nav_container');
  let headerBackground: HTMLElement | null =
    document.getElementById('nav_background');
  let logoEle: HTMLElement | null = document.getElementById('nav_logo');

  const handleLink = (target: string) => {
    setOpenMenu(!openMenu);
    scrollToPage(target, location, navigate);

    setTimeout(() => {
      if (header !== null && headerBackground !== null && window.scrollY > 96) {
        headerBackground.style.top = '-10rem';
        header.style.top = '-10rem';
      }
    }, 600);
  };

  const handelHeaderAppearance = () => {
    if (header !== null && headerBackground !== null) {
      if (direction === 'up') {
        headerBackground.style.top = '0';
        header.style.top = '0';

        if (window.scrollY < 96) {
          headerBackground.style.background = 'transparent';
        }
      } else if (direction === 'down') {
        headerBackground.style.top = '-10rem';
        header.style.top = '-10rem';

        if (window.scrollY >= 96) {
          headerBackground.style.background = Constants.complementaryColorLight;
        }
      }
    }
  };

  window.onscroll = () => {
    handelHeaderAppearance();
  };

  useEffect(() => {
    if (openMenu) {
      document.body.classList.add('lock_scroll');
      document.getElementById('nav_menu')?.classList.add('open_sidemenu');

      if (logoEle !== null && document.body.clientWidth <= 1016) {
        logoEle.style.filter = 'invert(1)';
      }
    } else {
      document.body.classList.remove('lock_scroll');
      document.getElementById('nav_menu')?.classList.remove('open_sidemenu');

      if (logoEle !== null) {
        logoEle.style.filter = 'invert(0)';
      }
    }
  }, [logoEle, openMenu]);

  return (
    <div className='nav_wrapper'>
      <div id='nav_container'>
        <div className='nav_menu'>
          <div className='nav_menu_burger'>
            <input
              className='nav_menu_burger_cheeckbox'
              type='checkbox'
              onChange={() => setOpenMenu(!openMenu)}
              checked={openMenu}
            />
            <div>
              <span></span>
              <span></span>
            </div>
          </div>
        </div>
        <div className='nav_logo'>
          <img
            onClick={() => scrollToPage('home', location, navigate)}
            src={logo}
            alt={`${Constants.ACCOMMODATIONS_NAME} logo header`}
            id='nav_logo'
          />
        </div>
        <div className='nav_book'>
          <a
            href={Constants.bookNowLink}
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            <div>{lang.menu.bookNow}</div>
          </a>
        </div>
      </div>
      <div id='nav_background'></div>
      <div id='nav_menu'>
        <div className='nav_menu_list'>
          <div className='nav_menu_list_links'>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.HOME)}
            >
              home
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.ABOUT)}
            >
              {lang.menu.ourStory.toLowerCase()}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.ACCOMMODATIONS)}
            >
              {lang.menu.villas.toLowerCase()}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.STAYING)}
            >
              {lang.menu.stayingPeljesac.toLowerCase()}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.GALLERY)}
            >
              {lang.menu.gallery.toLowerCase()}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.LOCATION)}
            >
              {lang.menu.location.toLowerCase()}
            </div>
            <div
              className='opacity_transition_high'
              onClick={() => handleLink(Constants.FOOTER)}
            >
              {lang.menu.contactUs.toLowerCase()}
            </div>
            {/* {changeLanguageButton()} */}
          </div>
          <div className='nav_menu_list_image'>
            <img src={menu} alt={`${Constants.ACCOMMODATIONS_NAME} menu`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
