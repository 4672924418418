import About from './components/00_about/About';
import FacilitiesAndServices from './components/01_facilitiesAndServices/FacilitiesAndServices';
import Accomodations from './components/02_accommodations/Accommodations';
import Staying from './components/03_staying/Staying';
import Gallery from './components/04_gallery/Gallery';
import Location from './components/05_location/Location';
import hero from '../../assets/imgs/hero.jpg';
import Constants from '../../utils/Constants';
import { useEffect } from 'react';

const gallery = require.context('../../assets/imgs/home/02_gallery', true);
const galleryList = gallery.keys().map((image: any) => gallery(image));

const Home = ({ lang }: { lang: Locale.Keys }) => {
  useEffect(() => {
    document.title = 'Villas Mare & Luce';
  }, []);
  
  return (
    <div id={Constants.HOME}>
      <div className='home_container_hero'>
        <img src={hero} alt='hero' />
        <div className='home_container_hero_button'>
          <a
            href={Constants.bookNowLink}
            target='_blank'
            rel='noreferrer'
            className='opacity_transition_low'
          >
            <div>{lang.menu.bookNow}</div>
          </a>
        </div>
      </div>
      <h1 hidden>Zaškoj</h1>
      <About lang={lang} />
      <FacilitiesAndServices lang={lang} />
      <Accomodations lang={lang} />
      <Staying lang={lang} />
      <Gallery lang={lang} imageList={galleryList} />
      <Location lang={lang} />
    </div>
  );
};

export default Home;
