import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useLanguageSwitcher } from './locale/useLanguageSwitcher';
import { hr } from './locale/hr';
import { en } from './locale/en';
import Layout from './pages/Layout';
import Home from './pages/home/Home';
import Luce from './pages/00_accommodation/villas/Luce';
import Mare from './pages/00_accommodation/villas/Mare';
import OnReloadScrollToTop from './utils/OnReloadScrollToTop';
import Constants from './utils/Constants';

const App: React.FC = () => {
  const { currentLanguage, changeLanguage, getLanguageFromURL } =
    useLanguageSwitcher();

  const lang: Locale.Keys = currentLanguage === Constants.lang.en ? en : hr;
  let doc: HTMLElement = document.documentElement;

  OnReloadScrollToTop();

  const setHight = () => {
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
  };

  useEffect(() => {
    setHight();
  }, []);

  window.addEventListener('resize', setHight);

  useEffect(() => {
    getLanguageFromURL();
  }, [getLanguageFromURL]);

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path='/'
          element={
            <Layout
              currentLanguage={currentLanguage}
              changeLanguage={changeLanguage}
              lang={lang}
            />
          }
        >
          <Route index element={<Home lang={lang} />} />
          <Route path={Constants.VILLA_LUCE} element={<Luce lang={lang} />} />
          <Route path={Constants.VILLA_MARE} element={<Mare lang={lang} />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
