import { useLocation, useNavigate } from 'react-router-dom';
import scrollToPage from '../../utils/ScrollToLink';
import logo from '../../assets/imgs/logo_gold.svg';
import Constants from '../../utils/Constants';

const Footer = () => {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div id={Constants.FOOTER}>
      <div className='footer_contact'>
        <div
          className='footer_logo'
          onClick={() => scrollToPage('home', location, navigate)}
        >
          <img src={logo} alt='mare & luce footer logo' />
        </div>
        <p>Zaškoj BB</p>
        <p>Dingač - Zaškoj</p>
        <p>
          <a href='tel:+385 21 293 100' className='opacity_transition_low'>
            +385 98 571 226
          </a>
        </p>
        <p>
          <a
            href='mailto:reservations@zaskoj.com'
            className='opacity_transition_low'
          >
            reservations@zaskoj.com
          </a>
        </p>
      </div>
      {/* <div className='footer_links'>
        <div
          className='opacity_transition_high'
          onClick={() => scrollToPage('impressum', location, navigate)}
        >
          IMPRESSUM
        </div>
        <div
          className='opacity_transition_high'
          onClick={() => scrollToPage('rules', location, navigate)}
        >
          HOTEL RULES
        </div>
      </div> */}
      <div className='footer_bottom'>
        <p>Copyright © Zaškoj {new Date().getFullYear()}.</p>
      </div>
    </div>
  );
};

export default Footer;
