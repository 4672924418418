import { GoogleMap, useJsApiLoader } from '@react-google-maps/api';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';

const mapProps = {
  cordinations: {
    lat: 42.913284,
    lng: 17.366682,
  },
  zoom: 18,
};

const Location = ({ lang }: { lang: Locale.Keys }) => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: String(process.env.REACT_APP_GOOGLE_MAP_KEY),
  });

  return (
    <div id={Constants.LOCATION}>
      <div className='location container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
          <ScrollAnimation animateIn='fadeIn' duration={1000}>
            <h2>{lang.location.title}</h2>
            {isLoaded && (
              <GoogleMap
                id='location_container'
                center={mapProps.cordinations}
                zoom={mapProps.zoom}
                mapTypeId= 'hybrid'
                
              ></GoogleMap>
            )}
          </ScrollAnimation>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Location;
