import { Outlet } from 'react-router-dom';
import Header from '../components/00_header/Header';
import Footer from '../components/01_footer/Footer';

const Layout = ({
  currentLanguage,
  changeLanguage,
  lang,
}: {
  currentLanguage: string;
  changeLanguage: Function;
  lang: Locale.Keys;
}) => {
  return (
    <div className='body_wrapper'>
      <Header
        currentLanguage={currentLanguage}
        changeLanguage={changeLanguage}
        lang={lang}
      />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
