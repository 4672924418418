import { useLocation, useNavigate } from 'react-router-dom';
import luce from '../../../../assets/imgs/home/00_accommodations/luce.jpg';
import mare from '../../../../assets/imgs/home/00_accommodations/mare.jpg';
import scrollToPage from '../../../../utils/ScrollToLink';
import Constants from '../../../../utils/Constants';
import ScrollAnimation from '../../../../utils/ScrollAnimation';

interface Accommodation {
  accommodationtTitle: string;
  accommodationtLink: string;
  accommodationtImage: string;
}

const Accommodations = ({ lang }: { lang: Locale.Keys }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const accommodations: Array<Accommodation> = [
    {
      accommodationtTitle: lang.accomodations.luceTitle,
      accommodationtLink: Constants.VILLA_LUCE,
      accommodationtImage: luce,
    },
    {
      accommodationtTitle: lang.accomodations.mareTitle,
      accommodationtLink: Constants.VILLA_MARE,
      accommodationtImage: mare,
    },
  ];

  return (
    <div id={Constants.ACCOMMODATIONS}>
      <div className='accommodations container'>
        <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
          <h2 className='text_center'>{lang.accomodations.title}</h2>
          <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
            <h3 className='title_underline_dark_small'>
              {lang.accomodations.subtitle}
            </h3>
            <p>{lang.accomodations.p1}</p>
          </ScrollAnimation>
          {accommodations.map((x: Accommodation) => (
            <ScrollAnimation animateIn='fadeIn' duration={1000} delay={200}>
              <div className='accommodations_slick_container'>
                  <div className='accommodations_slick_container_title'>
                    {x.accommodationtTitle}
                  </div>
                <img
                  src={x.accommodationtImage}
                  alt={x.accommodationtTitle}
                  className='accommodations_slick_container_image'
                />
                <div
                  data-index={`${accommodations.indexOf(x)}`}
                  className='accommodations_slick_container_box'
                >
                  <div className='accommodations_slick_container_box_title title_underline_complementary_small'>
                    {x.accommodationtTitle}
                  </div>
                  <div className='accommodations_slick_container_box_links'>
                    <a
                      href={Constants.bookNowLink}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <div>{lang.menu.bookNow}</div>
                    </a>
                    <div
                      onClick={() =>
                        scrollToPage(x.accommodationtLink, location, navigate)
                      }
                    >
                      {lang.accomodations.showMore}
                    </div>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
          ))}
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Accommodations;
