import ScrollAnimation from '../../../../utils/ScrollAnimation';
import logo from '../../../../assets/imgs/logo_gold.svg';
import separator from '../../../../assets/imgs/separator.svg';
import Constants from '../../../../utils/Constants';

const About = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div id={Constants.ABOUT}>
      <ScrollAnimation animateIn='fadeIn' animateOut='fadeOut' duration={500}>
        <div className='about container'>
          <div className='about_logo'>
            <img src={logo} alt='mare & luce logo' />
          </div>
          <div className='about_text'>
            <ScrollAnimation animateIn='fadeInUp' duration={500}>
              <h2 className='title_underline_light'>{lang.about.t}</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
              <p>{lang.about.p1}</p>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={200}>
              <p>{lang.about.p2}</p>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeInUp' duration={500} delay={300}>
              <p>{lang.about.p3}</p>
            </ScrollAnimation>
          </div>
        </div>
      </ScrollAnimation>
      <div className='about_img'>
        <img src={separator} alt='separator' />
      </div>
    </div>
  );
};

export default About;
