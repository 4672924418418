import Constants from '../../../utils/Constants';
import ScrollAnimation from '../../../utils/ScrollAnimation';
import Amenities from '../components/Amenities';
import separator from '../../../assets/imgs/separator.svg';
import { useEffect } from 'react';
import Gallery from '../../home/components/04_gallery/Gallery';

const images = require.context(
  '../../../assets/imgs/00_accommodation/01_mare',
  true
);
const imageList = images.keys().map((image: any) => images(image));

const Mare = ({ lang }: { lang: Locale.Keys }) => {
  useEffect(() => {
    document.title = 'Villa Mare';
  }, []);

  return (
    <div id={Constants.VILLA_MARE} className='accommodation_wrapper'>
      <div className='accommodation_hero'>
        <img src={imageList[13]} alt={`${lang.villaMare.title} hero`} />
        <div className='accommodation_hero_title'>
          <h1>{lang.villaMare.title}</h1>
        </div>
      </div>
      <div className='accommodation_img'>
        <img src={separator} alt='separator' />
      </div>
      <div className='accommodation_container container'>
        <div className='accommodation_container_info'>
          <ScrollAnimation
            animateIn='fadeIn'
            animateOut='fadeOut'
            duration={500}
          >
            <div className='accommodation_container_info_text'>
              <ScrollAnimation animateIn='fadeInUp' duration={500}>
                {lang.villaMare.p1}
              </ScrollAnimation>
              <ScrollAnimation animateIn='fadeInUp' duration={500} delay={100}>
                <div className='accommodation_container_info_text_button'>
                  <a
                    href={Constants.bookNowLink}
                    target='_blank'
                    rel='noreferrer'
                    className='opacity_transition_low'
                  >
                    <div>{lang.menu.bookNow}</div>
                  </a>
                </div>
              </ScrollAnimation>
            </div>
            <Amenities lang={lang} />
          </ScrollAnimation>
        </div>
      </div>
      <Gallery lang={lang} imageList={imageList} />
    </div>
  );
};

export default Mare;
