import ScrollAnimation from "../../../utils/ScrollAnimation";

const Amenities = ({ lang }: { lang: Locale.Keys }) => {
  return (
    <div className='accommodation_container_info_details'>
      <div>
        <ScrollAnimation animateIn='fadeInUp' duration={500}>
          <p>{lang.amenities.entertainment.title}</p>
          <ul>
            <li>{lang.amenities.entertainment.tv}</li>
            <li>{lang.amenities.entertainment.tvRoom}</li>
            <li>{lang.amenities.entertainment.poolTable}</li>
            <li>{lang.amenities.entertainment.sauna}</li>
            <li>{lang.amenities.entertainment.gym}</li>
            <li>{lang.amenities.entertainment.pool}</li>
            <li>{lang.amenities.entertainment.wifi}</li>
            <li>{lang.amenities.entertainment.barbecue}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' duration={500}>
          <p>{lang.amenities.roomFeatures.title}</p>
          <ul>
            <li>{lang.amenities.roomFeatures.rooms}</li>
            <li>{lang.amenities.roomFeatures.ac}</li>
            <li>{lang.amenities.roomFeatures.safe}</li>
            <li>{lang.amenities.roomFeatures.wardrobe}</li>
            <li>{lang.amenities.roomFeatures.smoking}</li>
            <li>{lang.amenities.roomFeatures.beddings}</li>
            <li>{lang.amenities.roomFeatures.pillows}</li>
            <li>{lang.amenities.roomFeatures.curtains}</li>
          </ul>
        </ScrollAnimation>
      </div>
      <div>
      <ScrollAnimation animateIn='fadeInUp' duration={500}>
          <p>{lang.amenities.bath.title}</p>
          <ul>
            <li>{lang.amenities.bath.shower}</li>
            <li>{lang.amenities.bath.toiletries}</li>
            <li>{lang.amenities.bath.towels}</li>
            <li>{lang.amenities.bath.hairdryer}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' duration={500}>
          <p>{lang.amenities.dining.title}</p>
          <ul>
            <li>{lang.amenities.dining.kitchen}</li>
            <li>{lang.amenities.dining.tableAndChairs}</li>
            <li>{lang.amenities.dining.cutlery}</li>
          </ul>
        </ScrollAnimation>
        <ScrollAnimation animateIn='fadeInUp' duration={500}>
          <p>{lang.amenities.additional.title}</p>
          <ul>
            <li>{lang.amenities.additional.parking}</li>
            <li>{lang.amenities.additional.coffe}</li>
            <li>{lang.amenities.additional.washmachine}</li>
            <li>{lang.amenities.additional.microwave}</li>
            <li>{lang.amenities.additional.kettle}</li>
            <li>{lang.amenities.additional.beach}</li>
            <li>{lang.amenities.additional.bikes}</li>
            <li>{lang.amenities.additional.wellcomePack}</li>
          </ul>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Amenities;
